import React, { useState, useCallback, useEffect, useReducer, useContext } from 'react';
import { useForm } from 'react-hook-form';

import Version from './Version';

import classes from "./CardBootstrap.module.scss";
import 'bootstrap/dist/css/bootstrap.css';

import alarm_img from '../assets/alarm_img.svg';

import axios from 'axios';
import swal from 'sweetalert2';
import Head from './Head';

import { AuthContext } from './Auth-context';
import { setCookie, getCookie, removeCookie, getAllCookie } from './cookies';

import {
    GetPiInfoAll,
    GetBBInfoAll,
    GetSENInfoAll,
    UpdateSenCount,
    UpdateDevInfo,
    SyncDBFromServer,
    SyncDBToServer,
} from './auth-hook';

import { 
    Nav, 
    Navbar,
    Container,
    Table, 
    Card, 
    Col, 
    Row, 
    Modal, 
    Form,
} from 'react-bootstrap';

let dev_info = {
    pi_info : [],
    bb_info : [],
    sen_info : [],
}

function sort_asc_sen_number(a, b){
    return a.sen_number - b.sen_number;
}

const update_sort_asc = ( () => {
    if(dev_info.bb_info.length > 0){
        dev_info.bb_info.sort(sort_asc_sen_number);
    }
    if(dev_info.sen_info.length > 0){
        dev_info.sen_info.sort(sort_asc_sen_number);
    }
})

const Rootstatus = () => {
    let [DeviceInfo, setDeviceInfo] = useState(null);
    // let [currentDate, setCurrentDate] = useState(getDate());
    const { register, watch, formState: { errors } } = useForm({mode: "onChange"});

    const { userState } = useContext(AuthContext);

    // setInterval(() => {
    //     setCurrentDate(getDate());
    // }, 1000)
    
    function configstate(state, action){
        switch(action.type){
            case "Temperature":
                return "Temperature";
            case "Humidity":
                return "Humidity";
            case "Temperature/Humidity":
                return "Temperature/Humidity";
        }
    }

    const [confType, toggleconfType] = useReducer(
        configstate,
        "Temperature"
    );

    let State_options = [
        { value: 'Temperature', label: 'Temp.', className:classes.select_temp},
        { value: 'Humidity', label: 'Hum.', className:classes.select_hum},
        { value: 'Temperature/Humidity', label: 'Both', className:classes.select_both},
    ];

    let Sensor_count_options = [
        { value: '1', label: '1', className:classes.select_temp},
        { value: '2', label: '2', className:classes.select_temp},
        { value: '3', label: '3', className:classes.select_temp},
        { value: '4', label: '4', className:classes.select_temp},
        { value: '5', label: '5', className:classes.select_temp},
        { value: '6', label: '6', className:classes.select_temp},
        { value: '7', label: '7', className:classes.select_temp},
        { value: '8', label: '8', className:classes.select_temp},
        { value: '9', label: '9', className:classes.select_temp},
        { value: '10', label: '10', className:classes.select_temp},
        { value: '11', label: '11', className:classes.select_temp},
        { value: '12', label: '12', className:classes.select_temp},
        { value: '13', label: '13', className:classes.select_temp},
        { value: '14', label: '14', className:classes.select_temp},
        { value: '15', label: '15', className:classes.select_temp},
        { value: '16', label: '16', className:classes.select_temp},
    ];

    const fetchData = useCallback(async() => {
        await SyncDBFromServer();
        await get_pi_dev_info();

        if(dev_info.pi_info.length > 0){
            if(dev_info.pi_info[0].bb_en){
                await get_bb_dev_info();
            }
    
            if(dev_info.pi_info[0].sen_en){
                await get_sen_dev_info();
            }
    
            update_sort_asc();
    
            setDeviceInfo(dev_info);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchData().catch();
    },[fetchData])

    const get_pi_dev_info = async() => {
        try {
            const data = await GetPiInfoAll();

            for(let i = 0; i < data.length; i++) {
                dev_info.pi_info.push(data[i]);

                if(data[i].sensor_type === 'Temperature') {
                    toggleconfType({type : 'Temperature'});
                }
                else if(data[i].sensor_type === 'Humidity') {
                    toggleconfType({type : 'Humidity'});
                }
                else if(data[i].sensor_type === 'Temperature/Humidity') {
                    toggleconfType({type : 'Temperature/Humidity'});
                }
            }

            return await data;
        } catch (error) {
            // empty
        }
    }

    const get_bb_dev_info = async() => {
        try {
            const data = await GetBBInfoAll();
        
            for(let i = 0; i < data.length; i++) {
                dev_info.bb_info.push(data[i]);
            }

            return await data;
        } catch (error) {
            // empty
        }
    }

    const get_sen_dev_info = async() => {
        try {
            const data = await GetSENInfoAll();
        
            for(let i = 0; i < data.length; i++) {
                dev_info.sen_info.push(data[i]);
            }

            return await data;
        } catch (error) {
            // empty
        }
    }

    const init_array = async() => {
        dev_info = {
            pi_info : [],
            bb_info : [],
            sen_info : [],
        }
        
        setDeviceInfo(null);
    }

    function AlarmState(props){
        let AlarmUpdate = ((key) => {
            dev_info.pi_info[0].sensor_type = key.target.value;

            State_options.map(idx => {
                if(idx.label === key.target.value) {
                    key.target.className += " " + idx.className

                    key.target.blur();
                }
            })
        })
        
        return (
            <Form.Control as="select" id={props.id} key={props.id} defaultValue={props.defaultValue} onChange={AlarmUpdate.bind(this)}>
                {State_options.map(opt => (
                    <option key={props.id + opt.value} value={opt.value} className={opt.className} >{opt.label}</option>
                ))}
            </Form.Control>
        )
    }

    const update_sen_count_local = async(dev_info) => {
        try {
            const data = await UpdateSenCount(dev_info);

            return await data;
        } catch (error) {
            // empty
        }
    }

    function Sensor_CountState(props){
        let SensorCountUpdate = ((key) => {
            dev_info.pi_info[0].sensor_count = Number(key.target.value);

            Sensor_count_options.map(idx => {
                if(idx.label === key.target.value) {
                    key.target.className += " " + idx.className

                    key.target.blur();
                }
            })

            update_sen_count_local(dev_info)
            .then(() => {
                setTimeout(function (){
                    window.location.reload();
                }, 500);
            });
        })

        if(dev_info.pi_info[0].sensor_count > dev_info.sen_info.length) {
            swal.fire({
                title: 'Adjust Sensor Count',
                html: `Can not set count <span style="color:red; font-weight:bold;">${dev_info.pi_info[0].sensor_count}</span>.<br>Need more sensor(s).`,
                timer: 3000,
                toast:true,
                icon: "error",
                position: "center",
                showConfirmButton: false,
                timerProgressBar : true,
            })
            .then(() => {
                dev_info.pi_info[0].sensor_count = dev_info.sen_info.length;

                update_sen_count_local(dev_info)
                .then(() => {
                    setTimeout(function (){
                        window.location.reload();
                    }, 500);
                });
            })
        }

        return (
            <Form.Control as="select" id={props.id} key={props.id} defaultValue={props.defaultValue} onChange={SensorCountUpdate.bind(this)}>
                {Sensor_count_options.map(opt => (
                    <option key={props.id + opt.value} value={opt.value} className={opt.className} >{opt.label}</option>
                ))}
            </Form.Control>
        )
    }
    
    const Pi_Info = ((props) => {
        const pi_list_info = [];
        const pi_title_info = [];

        let set_bb_en;
        let set_sen_en;
        let set_cloud_en;

        let bb_en_flag = "Disable";
        let sen_en_flag;
        let cloud_en_flag;

        if(dev_info.pi_info.length > 0){
            dev_info.pi_info[0].bb_en === 0 ? (set_bb_en = 0) : (set_bb_en = 1);
            dev_info.pi_info[0].sen_en === 0 ? (set_sen_en = 0) : (set_sen_en = 1);
            dev_info.pi_info[0].cloud_en === 0 ? (set_cloud_en = 0) : (set_cloud_en = 1);
        }
        
        let event_set_en = ((key) => {
            if(key.target.id === 'bb_en') {
                if(key.target.checked){
                    dev_info.pi_info[0].bb_en = 1;
                    bb_en_flag = "Enable"
                }
                else {
                    dev_info.pi_info[0].bb_en = 0;
                    bb_en_flag = "Disable"
                }
                
            }
            if(key.target.id === 'sen_en') {
                if(key.target.checked){
                    dev_info.pi_info[0].sen_en = 1;
                    sen_en_flag = "Enable"
                }
                else {
                    dev_info.pi_info[0].sen_en = 0;
                    sen_en_flag = "Disable"
                }
            }
            if(key.target.id === 'cloud_en') {
                if(key.target.checked){
                    dev_info.pi_info[0].cloud_en = 1;
                    cloud_en_flag = "Enable"
                }
                else {
                    dev_info.pi_info[0].cloud_en = 0;
                    cloud_en_flag = "Disable"
                }
            }

            const newArr = [...dev_info.pi_info];
            setDeviceInfo(newArr);
        })

        let disp_temperature = false;
        let disp_humidity = false;

        if(props.type === "Temperature") {
            disp_temperature = true;
        }
        else if(props.type === "Humidity") {
            disp_humidity = true;
        }
        else if(props.type === "Temperature/Humidity") {
            disp_temperature = true;
            disp_humidity = true;
        }
        
        for(let idx1 = 0; idx1 < dev_info.pi_info.length; idx1++){
            pi_list_info.push(
                <tbody key={ 'tBody_b_' + idx1 } id={ 'tBody_b_' + idx1 } >
                    <tr>
                        <td width="50%">
                            <label htmlFor='pi_name' className={ classes.text_black }>Device Name</label>
                        </td>
                        <td className={ classes.text_red }>
                            <Form.Control id={ 'pi_name_' +  dev_info.pi_info[idx1].pi_name } placeholder={ dev_info.pi_info[idx1].pi_name } />
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <label htmlFor='pi_mac' className={ classes.text_black }>Mac Address</label>
                        </td>
                        <td>
                            <label htmlFor='pi_mac' className={ classes.text_red }>{dev_info.pi_info[idx1].pi_mac}</label>
                        </td>
                    </tr>
                    
                    <tr>
                        <td>
                            <label htmlFor='cloud_en_flag' className={ classes.text_black }>Cloud Enable</label>
                        </td>
                        <td>
                            <input className="form-check-input" type="checkbox" role="switch" id="cloud_en" defaultChecked={set_cloud_en} onChange={event_set_en} />
                            <label htmlFor='cloud_en_flag' className={ classes.text_red }>{dev_info.pi_info[idx1].cloud_en}</label>
                        </td>
                    </tr>
                    
                    <tr>
                        <td>
                            <label htmlFor='sen_en_flag' className={ classes.text_black }>Sensor Enable</label>
                        </td>
                        <td>
                            <input className="form-check-input" type="checkbox" role="switch" id="sen_en" defaultChecked={set_sen_en} onChange={event_set_en} />
                            <label htmlFor='sen_en_flag' className={ classes.text_red }>{dev_info.pi_info[idx1].sen_en}</label>
                        </td>
                    </tr>
                    
                    <tr>
                        <td>
                            <label htmlFor='bb_en_flag' className={ classes.text_black }>Beacon Enable </label>
                        </td>
                        <td>
                            <input className="form-check-input" type="checkbox" role="switch" id="bb_en" defaultChecked={set_bb_en} onChange={event_set_en} />
                            <label htmlFor='bb_en_flag' className={ classes.text_red }>{dev_info.pi_info[idx1].bb_en}</label>
                        </td>
                    </tr>
                    
                    {disp_temperature && <tr>
                        <td>
                            <label htmlFor='over_temp_' className={ classes.text_temp }>Over Temp.</label>
                        </td>
                        <td className={ classes.text_red }>
                            <Form.Control id={ 'over_temp_' +  dev_info.pi_info[idx1].over_temp } defaultValue={ dev_info.pi_info[idx1].over_temp } 
                                {...register('over_temp', {
                                    valueAsNumber:true,
                                    max : {
                                        value : 130,
                                        message : 'Can not over set 130'
                                    },
                                    min : {
                                        value : -20,
                                        message : 'Can not under set -20'
                                    },
                                })}
                            />
                            {errors?.over_temp?.message}
                        </td>
                    </tr>
                    }
                    
                    {disp_temperature && <tr>
                        <td>
                            <label htmlFor='under_temp' className={ classes.text_temp }>Under Temp.</label>
                        </td>
                        <td className={ classes.text_red }>
                            <Form.Control id={ 'under_temp_' +  dev_info.pi_info[idx1].under_temp } defaultValue={ dev_info.pi_info[idx1].under_temp } 
                                {...register('under_temp', {
                                    valueAsNumber:true,
                                    max : {
                                        value : 130,
                                        message : 'Can not over set 130'
                                    },
                                    min : {
                                        value : -20,
                                        message : 'Can not under set -20'
                                    },
                                })}
                            />
                            {errors?.under_temp?.message}
                        </td>
                    </tr>
                    }

                    {disp_humidity && <tr>
                        <td>
                            <label htmlFor='over_hum' className={ classes.text_hum }>Over Hum.</label>
                        </td>
                        <td className={ classes.text_red }>
                            <Form.Control id={ 'over_hum_' +  dev_info.pi_info[idx1].over_hum } defaultValue={ dev_info.pi_info[idx1].over_hum } 
                                {...register('over_hum', {
                                    valueAsNumber:true,
                                    max : {
                                        value : 100,
                                        message : 'Can not over set 100'
                                    },
                                    min : {
                                        value : -1,
                                        message : 'Can not under set -1'
                                    },
                                })}
                            />
                            {errors?.over_hum?.message}
                        </td>
                    </tr>
                    }

                    {disp_humidity && <tr>
                        <td>
                            <label htmlFor='under_hum' className={ classes.text_hum }>Under Hum.</label>
                        </td>
                        <td className={ classes.text_red }>
                            <Form.Control id={ 'under_hum_' +  dev_info.pi_info[idx1].under_hum } defaultValue={ dev_info.pi_info[idx1].under_hum } 
                                {...register('under_hum', {
                                    valueAsNumber:true,
                                    max : {
                                        value : 100,
                                        message : 'Can not over set 100'
                                    },
                                    min : {
                                        value : -1,
                                        message : 'Can not under set -1'
                                    },
                                })}
                            />
                            {errors?.under_hum?.message}
                        </td>
                    </tr>
                    }
                    
                    <tr>
                        <td>
                            <label htmlFor='major' className={ classes.text_black }>Major</label>
                        </td>
                        <td className={ classes.text_red }>
                            <Form.Control id={ 'major_' + dev_info.pi_info[idx1].major } placeholder={ dev_info.pi_info[idx1].major } />
                        </td>
                    </tr>
                    
                    <tr>
                        <td>
                            <label htmlFor='minor' className={ classes.text_black }>Minor</label>
                        </td>
                        <td className={ classes.text_red }>
                            <Form.Control id={'minor_' +  dev_info.pi_info[idx1].minor } placeholder={ dev_info.pi_info[idx1].minor } />
                        </td>
                    </tr>
                </tbody>
            )
        }

        if(dev_info.pi_info.length > 0){
            pi_title_info.push(
                <Col key={'col_b_' + 0} id={'col_b_' + 0} >
                    <Card key={'card_b_' + 0} id={'card_b_' + 0} className={classes.pi_info_card}>
                        <Card.Body key={'cardbody_b_' + 0} id={'cardbody_b_' + 0}>
                            <Card.Title id={ 'pi_mac_' + dev_info.pi_info[0].pi_mac } >
                                <Form>
                                    <Row className={classes.cardTitle}>
                                        <Col sm={6} xs={6}>
                                            <h3 htmlFor='pi_info' className={ classes.boldtext_black }>Device Info</h3>
                                        </Col>

                                        <Col sm={1} xs={1}>
                                            <img src={alarm_img} className={classes.limiticon} alt="face"/>
                                        </Col>

                                        <Col sm={5} xs={5}>
                                            <AlarmState select={dev_info.pi_info[0].sensor_type} id="sensor_type" defaultValue={dev_info.pi_info[0].sensor_type} />
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Title>

                            <Card.Text as="div" id = {dev_info.pi_info[0].pi_dev_id} >
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <td>
                                                <label htmlFor='pi_dev_id' className={ classes.table_title }>Items</label>
                                            </td>
                                            <td>
                                                <label htmlFor='pi_info' className={ classes.table_title }>Description</label>
                                            </td>
                                        </tr>
                                    </thead>

                                    {pi_list_info}
                                </Table>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            )
        }

        return(
            <div>
                <Row xs={1} sm={1} md={2} xl={3} xxl={8} className="values" id={'Rows_pi_info'}>
                    {pi_title_info}
				</Row>
            </div>
        )
    })

    const Bb_Info = (() =>{
        const bb_list_info = [];
        const bb_title_info = [];

        for(let idx1 = 0; idx1 < dev_info.bb_info.length; idx1++){
            bb_list_info.push(
                <tbody key={ 'tBody_b_' + idx1 } id={ 'tBody_b_' + idx1 } >
                    <tr>
                        <td>
                            <label htmlFor='bb_number' className={ classes.sensor_info_text_center }>{dev_info.bb_info[idx1].bb_number}</label>
                        </td>

                        <td>
                            <label htmlFor='bb_mac' className={ classes.sensor_info_text_center }>{dev_info.bb_info[idx1].bb_mac}</label>
                        </td>
                        
                        <td className={ classes.text_red } >
                            <Form.Control id={ dev_info.bb_info[idx1].bb_mac } placeholder={ dev_info.bb_info[idx1].bb_name } />
                        </td>
                    </tr>
                </tbody>
            )
        }

        if(dev_info.bb_info.length > 0){
            bb_title_info.push(
                <Col key={'col_b_' + 0} id={'col_b_' + 0} >
                    <Card key={'card_b_' + 0} id={'card_b_' + 0} className={classes.pi_info_card}>
                        <Card.Body key={'cardbody_b_' + 0} id={'cardbody_b_' + 0}>
                            <Card.Title id={ 'bb_dev_id_' + dev_info.bb_info[0].bb_dev_id } >
                                <Form>
                                    <Row className={classes.cardTitle}>
                                        <h3 htmlFor='bb_info' className={ classes.boldtext_black }>Beacon Info</h3>
                                    </Row>
                                </Form>
                            </Card.Title>

                            <Card.Text as="div" id = {dev_info.bb_info[0].pi_dev_id} >
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <td>
                                                <label htmlFor='bb_dev_id' className={ classes.table_title }>ID</label>
                                            </td>
                                            <td>
                                                <label htmlFor='bb_dev_id' className={ classes.table_title }>Mac</label>
                                            </td>
                                            <td>
                                                <label htmlFor='bb_dev_id' className={ classes.table_title }>Name</label>
                                            </td>
                                        </tr>
                                    </thead>

                                    {bb_list_info}
                                </Table>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            )
        }

        return(
            <div>
                <Row xs={1} sm={1} md={2} xl={3} xxl={8} className="values" id={'Rows_bb_info'}>
                    {bb_title_info}
				</Row>
            </div>

        )
    })

    const Sen_Info = (() =>{
        const sen_list_info = [];
        const sen_title_info = [];

        for(let idx1 = 0; idx1 < dev_info.sen_info.length; idx1++){
            sen_list_info.push(
                <tbody key={ 'tBody_b_' + idx1 } id={ 'tBody_b_' + idx1 } >
                    <tr>
                        <td>
                            <label htmlFor='cham_id' className={ classes.sensor_info_text_center }>{dev_info.sen_info[idx1].cham_id}</label>
                        </td>

                        <td>
                            <label htmlFor='sen_number' className={ classes.sensor_info_text_center }>{dev_info.sen_info[idx1].sen_number}</label>
                        </td>
                        
                        <td className={ classes.text_red } >
                            <Form.Control id={ 'sen_number_' + dev_info.sen_info[idx1].sen_number } placeholder={ dev_info.sen_info[idx1].sen_name } />
                        </td>
                    </tr>
                </tbody>
            )
        }

        if(dev_info.sen_info.length > 0){
            sen_title_info.push(
                <Col key={'col_s_' + 0} id={'col_s_' + 0} >
                    <Card key={'card_s_' + 0} id={'card_s_' + 0} className={classes.pi_info_card}>
                        <Card.Body key={'cardbody_s_' + 0} id={'cardbody_s_' + 0}>
                            <Card.Title id={ 'sen_dev_id_' + dev_info.sen_info[0].bb_dev_id } >
                                <Form>
                                    <Row className={classes.cardTitle}>
                                        <Col sm={6} xs={6}>
                                            <h3 htmlFor='sen_info' className={ classes.boldtext_black }>Sensor Info</h3>
                                        </Col>

                                        <Col sm={3} xs={3}>
                                            <label htmlFor='sen_count' className={ classes.sensor_info_text_center }>Count</label>
                                        </Col>
                                        
                                        <Col sm={3} xs={3}>
                                            <Sensor_CountState select={dev_info.pi_info[0].sensor_count} id="sensor_count" defaultValue={dev_info.pi_info[0].sensor_count} />
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Title>

                            <Card.Text as="div" id = {dev_info.sen_info[0].pi_dev_id} >
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <td width="30%">
                                                <label htmlFor='sen_cham_id' className={ classes.table_title }>Cham ID</label>
                                            </td>
                                            <td width="25%">
                                                <label htmlFor='sen_no' className={ classes.table_title }>Sen No.</label>
                                            </td>
                                            <td>
                                                <label htmlFor='sen_name' className={ classes.table_title }>Name</label>
                                            </td>
                                        </tr>
                                    </thead>
                                        
                                    {sen_list_info}
                                </Table>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            )
        }

        return(
            <div>
                <Row xs={1} sm={1} md={2} xl={3} xxl={8} className="values" id={'Rows_sen_info'}>
                    {sen_title_info}
				</Row>
            </div>

        )
    })

    const BottomButtons = (() => {
        return (
            <div id='bottom_btns_layout' className={classes.config_align_space_between}>
                <Col>
                    <Row>
                        <Col sm={6} xs={6}><button type='button' className={classes.configOKBtn} onClick={submit_handleClick}>Submit</button></Col>
                        <Col sm={6} xs={6}><button type='button' className={classes.configCancelBtn} onClick={cancel_handleClick}>Cancel</button></Col>
                    </Row>
                </Col>
			</div>
        )
    })

    const update_dev_info_local = async(dev_info) => {
        try {
            const data = await SyncDBToServer(dev_info);

            return await data;
        } catch (error) {
            // empty
        }
    }

    const submit_handleClick = () => {
        for(let idx1 = 0; idx1 < dev_info.pi_info.length; idx1++){
            let pi_name_ele = document.getElementById('pi_name_' +  dev_info.pi_info[idx1].pi_name);

            let major_ele = document.getElementById('major_' + dev_info.pi_info[idx1].major);
            let minor_ele = document.getElementById('minor_' + dev_info.pi_info[idx1].minor);

            // NOT changed pi_name
            if(pi_name_ele.value === ""){
                dev_info.pi_info[idx1].pi_name = pi_name_ele.placeholder;
            }
            // changed pi_name
            else {
                dev_info.pi_info[idx1].pi_name = pi_name_ele.value;
            }

            if(major_ele.value === ""){
                dev_info.pi_info[idx1].major = major_ele.placeholder;
            }
            // changed major
            else {
                dev_info.pi_info[idx1].major = major_ele.value;
            }

            if(minor_ele.value === ""){
                dev_info.pi_info[idx1].minor = minor_ele.placeholder;
            }
            // changed minor
            else {
                dev_info.pi_info[idx1].minor = minor_ele.value;
            }

            // pi_type(to sync with server)
            let pi_type;
            if( (dev_info.pi_info[idx1].bb_en === 1) && (dev_info.pi_info[idx1].sen_en === 0)) {
                pi_type = 'Beacon';
            }
            else if( (dev_info.pi_info[idx1].bb_en === 0) && (dev_info.pi_info[idx1].sen_en === 1)) {
                pi_type = 'Wired';
            }
            else if( (dev_info.pi_info[idx1].bb_en === 0) && (dev_info.pi_info[idx1].sen_en === 0)) {
                pi_type = 'None';
            }
            else if( (dev_info.pi_info[idx1].bb_en === 1) && (dev_info.pi_info[idx1].sen_en === 1)) {
                pi_type = 'None';
            }
            dev_info.pi_info[idx1].pi_type = pi_type;

            // Temperature
            if( (confType === "Temperature") || (confType === "Temperature/Humidity")) {

                let under_temp_ele = document.getElementById('under_temp_' +  dev_info.pi_info[idx1].under_temp);
                let over_temp_ele = document.getElementById('over_temp_' +  dev_info.pi_info[idx1].over_temp);
                
                if( ( (over_temp_ele.value === "") && (under_temp_ele.value === ""))) {
                    dev_info.pi_info[idx1].over_temp = Number(over_temp_ele.placeholder);
                    dev_info.pi_info[idx1].under_temp = Number(under_temp_ele.placeholder);
                }
                else if( ( (over_temp_ele.value !== "") && (under_temp_ele.value === ""))) {
                    dev_info.pi_info[idx1].over_temp = Number(over_temp_ele.value);
                    dev_info.pi_info[idx1].under_temp = Number(under_temp_ele.placeholder);
                }
                else if( ( (over_temp_ele.value === "") && (under_temp_ele.value !== ""))) {
                    dev_info.pi_info[idx1].over_temp = Number(over_temp_ele.placeholder);
                    dev_info.pi_info[idx1].under_temp = Number(under_temp_ele.value);
                }
                else if( ( (over_temp_ele.value !== "") && (under_temp_ele.value !== ""))) {
                    if( Number(over_temp_ele.value) <= Number(under_temp_ele.value) ){
                        swal.fire({
                            title: 'Adjust Range(Temperature)',
                            text: `Can not bigger of 'Under Temperature value' better than 'Over Temperature value'.`,
                            timer: 3000,
                            toast:true,
                            icon: "error",
                            position: "center",
                            showConfirmButton: false,
                            timerProgressBar : true,
                        })
    
                        return false;
                    }
                    else {
                        dev_info.pi_info[idx1].over_temp = Number(over_temp_ele.value);
                        dev_info.pi_info[idx1].under_temp = Number(under_temp_ele.value);
                    }
                }
            }
            
            // Humidity
            if( (confType === "Humidity") || (confType === "Temperature/Humidity")) {
                let under_hum_ele = document.getElementById('under_hum_' +  dev_info.pi_info[idx1].under_hum);
                let over_hum_ele = document.getElementById('over_hum_' +  dev_info.pi_info[idx1].over_hum);
    
                if( ( (over_hum_ele.value === "") && (under_hum_ele.value === ""))) {
                    dev_info.pi_info[idx1].over_hum = Number(over_hum_ele.placeholder);
                    dev_info.pi_info[idx1].under_hum = Number(under_hum_ele.placeholder);
                }
                else if( ( (over_hum_ele.value !== "") && (under_hum_ele.value === ""))) {
                    dev_info.pi_info[idx1].over_hum = Number(over_hum_ele.value);
                    dev_info.pi_info[idx1].under_hum = Number(under_hum_ele.placeholder);
                }
                else if( ( (over_hum_ele.value === "") && (under_hum_ele.value !== ""))) {
                    dev_info.pi_info[idx1].over_hum = Number(over_hum_ele.placeholder);
                    dev_info.pi_info[idx1].under_hum = Number(under_hum_ele.value);
                }
                else if( ( (over_hum_ele.value !== "") && (under_hum_ele.value !== ""))) {
                    if( Number(over_hum_ele.value) <= Number(under_hum_ele.value) ){
                        swal.fire({
                            title: 'Adjust Range(Humidity)',
                            text: `Can not bigger of 'Under Humidity value' better than 'Over Humidity value'.`,
                            timer: 3000,
                            toast:true,
                            icon: "error",
                            position: "center",
                            showConfirmButton: false,
                            timerProgressBar : true,
                        })
    
                        return false;
                    }
                    else {
                        dev_info.pi_info[idx1].over_hum = Number(over_hum_ele.value);
                        dev_info.pi_info[idx1].under_hum = Number(under_hum_ele.value);
                    }
                }
            }
        }
        
        for(let idx1 = 0; idx1 < dev_info.bb_info.length; idx1++){
            let bb_name_ele = document.getElementById(dev_info.bb_info[idx1].bb_mac);
            
            // NOT changed bb_name
            if(bb_name_ele.value === ""){
                dev_info.bb_info[idx1].bb_name = bb_name_ele.placeholder;
            }
            // changed bb_name
            else {
                dev_info.bb_info[idx1].bb_name = bb_name_ele.value;
            }
        }

        for(let idx1 = 0; idx1 < dev_info.sen_info.length; idx1++){
            let sen_name_ele = document.getElementById('sen_number_' + dev_info.sen_info[idx1].sen_number);

            // NOT changed sen_name
            if(sen_name_ele.value === ""){
                dev_info.sen_info[idx1].sen_name = sen_name_ele.placeholder;
            }
            // changed sen_name
            else {
                dev_info.sen_info[idx1].sen_name = sen_name_ele.value;
            }
        }

        let update_local_complete = false;
        let update_server_complete = false;

        update_dev_info_local(dev_info)
        .then((results) => {
            if(results.result === 'updated'){
                update_local_complete = true;
            }
        })
        .then(() => {
            init_array()
            .then( () => {
                fetchData().catch();
            })
        })
    }

    const cancel_handleClick = () => {
        let get_item = localStorage.getItem('userState');

        if(get_item !== null){
            localStorage.removeItem('userState');
        }
        
        setCookie('disp_gauge', true, {
			path:'/',
		})
		setCookie('disp_timegraph', false, {
			path:'/',
		})

        // let redirect_URL = '/login';
        let redirect_URL = '/';
        window.location.replace(redirect_URL);
    }

    // const reg_dev_btn = async() => {
    //     await reg_dev_seq();

    //     swal.fire({
    //         title: 'Registered',
    //         text: `Complete`,
    //         timer: 3000,
    //         toast:true,
    //         icon: "success",
    //         position: "center",
    //         showConfirmButton: false,
    //         timerProgressBar : true,
    //     })
    // }

    // const reg_dev_seq = async() => {
    //     let Check_URL;
    //     let pi_mac;
    //     let SUB_URL = '/kakao/register/pi';
    //     let env_host = process.env.REACT_APP_HOST;

    //     let location = window.location.hostname;
	//     let loc_port = window.location.port;

    //     if(location.includes('192.168.8.')) {
    //         Check_URL = 'https://' + env_host + SUB_URL;
    //     }
    //     else {
    //         Check_URL = 'https://' + location + ':7000' + SUB_URL;
    //     }

    //     if(dev_info.pi_info.length > 0){
    //         pi_mac = dev_info.pi_info[0].pi_mac;

    //         try {
    //             const {data} = await axios({
    //                 method: 'POST',
    //                 // url: SUB_URL,
    //                 url: Check_URL,
    //                 headers: {
    //                     "Content-Type": "application/json"
    //                 },
    //                 data: {
    //                     user_id: 1,
    //                     type: 'user',
    //                     pi_mac: pi_mac,
    //                 },
    //                 withCredentials: false,
    //                 credentials: "same-origin",
    //             })
        
    //             return await data;
    //         } catch (error) {
    //             swal.fire({
    //                 title: 'Registered',
    //                 text: error,
    //                 timer: 3000,
    //                 toast:true,
    //                 icon: "success",
    //                 position: "center",
    //                 showConfirmButton: false,
    //                 timerProgressBar : true,
    //             })
    //             // Lint no-Empty
    //         }
    //     }
    // }

    const reg_dev_btn = () => {
        let redirect_URL = userState.user + '/serverinfo';
        // let redirect_URL = 'server';
        window.location.replace(redirect_URL);
    }

    const user_status_btn = () => {
        let redirect_URL = userState.user + '/user';
        // let redirect_URL = 'user';
        window.location.replace(redirect_URL);
    }

    function HeadNav(){
		return (
			<div>
				<Navbar bg="secondary" variant="dark" collapseOnSelect expand='sm' >
                    <Container className={classes.navbarContainer} >
						<Navbar.Brand>Root Page</Navbar.Brand>
                        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                        <Navbar.Collapse id='responsive-navbar-nav'>
                            <Nav id="headnav" className="flex-grow-1 " >
                                <Nav.Link onClick={reg_dev_btn}>Reg Dev</Nav.Link>
                                <Nav.Link onClick={user_status_btn}>Users</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
			</div>
		)
	}

    return(
        <div>
            <Head />
            <HeadNav />

            <Version />
            {/* {currentDate} */}
            
            <Pi_Info type={confType}/>
            <Bb_Info />
            <Sen_Info />

            <BottomButtons />
        </div>
    )
}

export default Rootstatus;