import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const setCookie = (name: String, value: String, option?: any) => {
    return cookies.set(name, value, { ...option })
}

export const getCookie = (name: string) => {
    return cookies.get(name)
}

export const getAllCookie = () => {
    return cookies.getAll()
}

export const removeCookie = (name: string) => {
    let remove = document.cookie = name + '=; expires=0; path=/;'
    // return cookies.remove(name)
    
    // https://github.com/reactivestack/cookies/issues/346
    return remove
}