import React from 'react';

import NotFound from './NotFound';
import ChangeAdminPassword from './ChangeAdminPassword';
import Login from './Login';
import Rootstatus from './Rootstatus';
import Adminstatus from './Adminstatus';
import Userstatus from './Userstatus';
import Monitor from './Monitor';
import ServerInfo from './ServerInfo';

export const Pages = {
    Secret: [
        { id: 0, path:"root/status", title:'Root Status', element:<Rootstatus /> },
        { id: 1, path:"root/user", title:'User Status', element:<Userstatus /> },
        { id: 2, path:"root/serverinfo", title:'Server Status', element:<ServerInfo /> },
    ],
    Private : [
        { id: 3, path:"admin/changepassword", title:'Change Admin Password', element:<ChangeAdminPassword /> },
        { id: 4, path:"admin/status", title:'Admin Status', element:<Adminstatus /> },
        { id: 5, path:"admin/user", title:'User Status', element:<Userstatus /> },
        { id: 6, path:"admin/serverinfo", title:'Server Status', element:<ServerInfo /> },
    ],
    Public : [
        { id: 7, path:"monitor", title:'Monitor', element:<Monitor /> },
        { id: 8, path:"login", title:'Login', element:<Login /> },

        { id: 9, path:"root/*", title:'Not Found', element:<NotFound /> },
        { id: 10, path:"auth/*", title:'Not Found', element:<NotFound /> },

        { id: 11, path:"", title:'Monitor', element:<Monitor /> },

        { id: 12, path:"*", title:'Not Found', element:<NotFound /> },
    ]
}