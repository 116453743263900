import React from 'react';

import classes from './Head.module.scss';
import teamLogo from '../assets/KARI.svg';
import companyLogo from '../assets/KARI_CI.svg';
import ubattLogo from '../assets/UBATT_Logo_White_All.svg';


const Head = () => {
    return (
        <div
            className={classes.align_space_between}>
                <div className={classes.align_space} >
                    {/* <img 
                        className={classes.team_icon}
                        src={teamLogo}
                        alt="team Logo"
                        htmlFor='team-logo'
                    ></img>
                    <img 
                        className={classes.company_icon}
                        src={companyLogo}
                        alt="Company Logo"
                        htmlFor='company-logo'
                    ></img> */}
                </div>
            
            <img 
                className={classes.ubatt_icon}
                src={ubattLogo}
                alt="Ubatt Logo"
                htmlFor='ubatt-logo'
            ></img>
        </div>
    )
}

export default Head;