import React, { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { AuthContext } from './Auth-context';

const RootRoute = () => {
    const { userState } = useContext(AuthContext);

    return (userState.auth && userState.user ==='root')? <Outlet /> : <Navigate to="/monitor" />;
};

export default RootRoute;