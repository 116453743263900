import React, { useState, createContext, useContext } from 'react';
import axios from 'axios';

export const AuthContext = createContext(null);

let tokens = null;

export const AuthProvider = ({ children }) => {
    const [userState, setUserState] = useState({
        user: 'test',
        token:tokens,
        auth: false,
        state : 0
    });

    return (
        <>
            <AuthContext.Provider value={ {userState, setUserState} }>
                {children}
            </AuthContext.Provider>
        </>
    );
}

export const AuthState = () => {
    const { userState, setUserState } = useContext(AuthContext);

    return userState;
}

export const Token = async(user_id, accept_result) => {
    const { userState, setUserState } = useContext(AuthContext);

    let SUB_URL = '/token/login';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                id:user_id,
                accept: accept_result,
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        setUserState({user: user_id, token : tokens, auth : accept_result});

        return await data;  // result : token
    } catch (error) {
        // empty
    }
}

export const Check_token = async(user_id, accept_result) => {
    const { userState, setUserState } = useContext(AuthContext);

    let SUB_URL = '/token/check';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                Authorization: 'bearer ' + tokens.accessToken
            },
            data: {
                id:user_id,
                accept: accept_result,
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        setUserState({user: user_id, token : tokens, auth : accept_result});
        
        return await data;
    } catch (error) {
        // empty
    }
}