import React, { useContext, useCallback } from 'react';

import classes from "./Login.module.scss";
import usernameIcon from "../assets/icon_person.svg";
import passwordIcon from "../assets/icon_password.svg";

import swal from 'sweetalert2';
import { setCookie, getCookie, removeCookie, getAllCookie } from './cookies';

import { Auth, ResetPassword } from './auth-hook';
import { AuthContext } from './Auth-context';

const Login = () => {
    const { setUserState } = useContext(AuthContext);

    const login = async() => {
        let user_id = document.getElementById('user-name').value;
		let password = document.getElementById('user-password').value;
        
        if( (user_id.length === 0) && (password.length === 0)){
            await swal.fire({
                title: 'Login',
                text: 'Can not empty user id and password.',
                timer: 3000,
                toast:true,
                icon: "error",
                position: "center",
                showConfirmButton: false,
                timerProgressBar : true,
            })
        }
        else if( (user_id.length !== 0) && (password.length === 0)){
            await swal.fire({
                title: 'Login',
                text: 'Can not empty password.',
                timer: 3000,
                toast:true,
                icon: "error",
                position: "center",
                showConfirmButton: false,
                timerProgressBar : true,
            })
        }
        else if( (user_id.length === 0) && (password.length !== 0)){
            await swal.fire({
                title: 'Login',
                text: 'Can not empty user id.',
                timer: 3000,
                toast:true,
                icon: "error",
                position: "center",
                showConfirmButton: false,
                timerProgressBar : true,
            })
        }
        else if( (user_id.length !== 0) && (password.length !== 0)){
            return await Auth(user_id, password);
        }
    }

    const submitHandler = async() => {
        let results = await login();

        if(typeof results !== 'undefined' && results[0].user !== 'no_user') {
            let get_item = localStorage.getItem('userState');

            if(get_item !== null){
                localStorage.removeItem('userState');
            }

            // console.log('results.srv_ip_addr : ' +results[0].srv_ip_addr );
            
            if(results[0].user === 'root') {
                if(!!results[0].token){
                    let json_data = {
                        user: results[0].user,
                        token:results[0].token,
                        auth: true,
                        state : results[0].state
                    };
        
                    localStorage.setItem(
                        'userState',
                        JSON.stringify(json_data)
                    );
        
                    setUserState(json_data);
        
                    let redirect_URL = results[0].user + '/status';
                    window.location.replace(redirect_URL);
                }
            }
            else if(results[0].user === 'admin') {
                if(!!results[0].token){
                    let json_data = {
                        user: results[0].user,
                        token:results[0].token,
                        auth: true,
                        state : results[0].state
                    };
        
                    localStorage.setItem(
                        'userState',
                        JSON.stringify(json_data)
                    );
        
                    setUserState(json_data);

                    if(results[0].state === 0){
                        // change password page
                        let redirect_URL = 'admin/changepassword';
                        window.location.replace(redirect_URL);
                    }
                    else if(results[0].state === 1){
                        // redirect admin status
                        let redirect_URL = results[0].user + '/status';
                        window.location.replace(redirect_URL);
                    }
                }
            }
            else {
                swal.fire({
                    title: 'Login',
                    text: 'Please check your user id and password.',
                    timer: 3000,
                    toast:true,
                    icon: "error",
                    position: "center",
                    showConfirmButton: false,
                    timerProgressBar : true,
                })
            }
        }
        else {
            swal.fire({
                title: 'Login',
                text: 'Please check your user id and password.',
                timer: 3000,
                toast:true,
                icon: "error",
                position: "center",
                showConfirmButton: false,
                timerProgressBar : true,
            })
        }
	};

    const cancelHandler = () => {
        let get_item = localStorage.getItem('userState');

        if(get_item !== null){
            localStorage.removeItem('userState');
        }

        setCookie('disp_gauge', true, {
			path:'/',
		})
		setCookie('disp_timegraph', false, {
			path:'/',
		})

        // let redirect_URL = 'login';
        let redirect_URL = '/';
		window.location.replace(redirect_URL);
	};

    const resetHandler = async () => {
        let get_item = localStorage.getItem('userState');

        if(get_item !== null){
            localStorage.removeItem('userState');
        }

        let reset_result = await ResetPassword();

        if(Number(reset_result) > 0){
            await swal.fire({
                title: 'Reset Password',
                text: 'Complete Reset Admin Password.',
                timer: 2000,
                toast:true,
                icon: "success",
                position: "center",
                showConfirmButton: false,
                timerProgressBar : true,
            })
    
            let redirect_URL = 'login';
            window.location.replace(redirect_URL);
        }
        else {
            await swal.fire({
                title: 'Reset Password',
                text: 'Try again.',
                timer: 2000,
                toast:true,
                icon: "error",
                position: "center",
                showConfirmButton: false,
                timerProgressBar : true,
            })
        }
	};

    const checkSearchedWord = useCallback((event) => {
		let expText = /[%=*><'-]/;

		if(event.key === 'Enter'){
			submitHandler();
		}
		else if(expText.test(event.key) === true){
			alert(`Can not use %=*><'-`);
			event.preventDefault();
		}
	}, []);

    return(
        <div>
            <div>
                <h3 className={classes.head_title}>Login</h3>
            </div>
            <form className={classes.form}>
                <div>
                    <img
                        className={classes.login_icon}
                        src={usernameIcon}
                        alt="Username icon"
                        htmlFor="user-name"
                    ></img>
                    <input
                        className={classes.login_input}
                        type="text"
                        id="user-name"
                        name="user-name"
                        autoComplete="on"
                        placeholder="ID"
                        onKeyDown={checkSearchedWord}
                    ></input>
                </div>

                <div>
                    <img
                        className={classes.login_icon}
                        src={passwordIcon}
                        alt="Password icon"
                        htmlFor="user-password"
                    ></img>
                    <input
                        className={classes.login_input}
                        type="password"
                        id="user-password"
                        name="user-password"
                        autoComplete="off"
                        placeholder="Password"
                        onKeyDown={checkSearchedWord}
                    ></input>
                </div>

                <div className={classes.align_space_between}>
                    <button type='button' onClick={submitHandler} className={classes.loginBtn} >Login</button>
                    <button type='button' onClick={cancelHandler} className={classes.cancelBtn} >Cancel</button>
                    <button type='button' onClick={resetHandler} className={classes.resetBtn} >Reset</button>
                </div>
            </form>
        </div>
    )
}

export default Login;