import './App.css';

import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import { Pages } from './components/Pages';

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import RootRoute from './components/RootRoute';

import { AuthContext } from './components/Auth-context';

const App = () => {
	const { pathname } = useLocation();
	const title = process.env.REACT_APP_TITLE;

	let check_secret;
	let check_private;
	let check_public;

	let local_getitem = JSON.parse(localStorage.getItem('userState'));

	let json_data = {};

	if(local_getitem === null){
		json_data = {
			user: 'none',
			token:'',
			auth: false,
			state : 0
		};
	}
	else {
		json_data = {
			user: local_getitem.user,
			token:local_getitem.token,
			auth: local_getitem.auth,
			state : local_getitem.state
		};
	}

	const [userState, setUserState] = useState(json_data);

	useEffect(() => {
		let subTitle = '';

		if(process.env.REACT_APP_DEV_MODE === 'true') {
			check_secret = Pages.Secret.find((p) => {
				if(p.path === pathname.slice(1, pathname.length)) {
					return p.title;
				}
			});

			check_private = Pages.Private.find((p) => {
				if(p.path === pathname.slice(1, pathname.length)) {
					return p.title;
				}
			});

			check_public = Pages.Public.find((p) => {
				if(p.path === pathname.slice(1, pathname.length)) {
					return p.title;
				}
			});

			if(typeof check_secret !== 'undefined'){
				subTitle = check_secret.title;
			} else if(typeof check_private !== 'undefined'){
				subTitle = check_private.title;
			} else if(typeof check_public !== 'undefined'){
				subTitle = check_public.title;
			}
		}
		else {
			if(pathname === '/mon/') {
				check_secret = Pages.Secret.find((p) => {
					if(p.path === pathname.slice(1, pathname.length - 1)) {
						return p.title;
					}
				});

				check_private = Pages.Private.find((p) => {
					if(p.path === pathname.slice(1, pathname.length - 1)) {
						return p.title;
					}
				});

				check_public = Pages.Public.find((p) => {
					if(p.path === pathname.slice(1, pathname.length - 1)) {
						return p.title;
					}
				});
			
				if(typeof check_private !== 'undefined'){
					subTitle = check_private.title;
				} else if(typeof check_public !== 'undefined'){
					subTitle = check_public.title;
				}
			}
			else {
				check_secret = Pages.Secret.find((p) => {
					if(p.path === pathname.slice(1, pathname.length)) {
						return p.title;
					}
				});

				check_private = Pages.Private.find((p) => {
					if(p.path === pathname.slice(1, pathname.length)) {
						return p.title;
					}
				});

				check_public = Pages.Public.find((p) => {
					if(p.path === pathname.slice(1, pathname.length)) {
						return p.title;
					}
				});

				if(typeof check_secret !== 'undefined'){
					subTitle = check_secret.title;
				} else if(typeof check_private !== 'undefined'){
					subTitle = check_private.title;
				} else if(typeof check_public !== 'undefined'){
					subTitle = check_public.title;
				}
			}
		}

		if(subTitle === 'Home') {
			document.title = title;
		}
		else {
			document.title = title + ' - ' + subTitle;
		}
	}, [pathname])
	
	return(
		<div>
			<AuthContext.Provider value={ {userState, setUserState} }>
				<Routes>
					<Route element={ <RootRoute /> }>
						{Pages.Secret.map(p => <Route key={p.id} path={p.path} element={p.element} />)}
					</Route>

					<Route element={ <PrivateRoute /> } >
						{Pages.Private.map(p => <Route key={p.id} path={p.path} element={p.element} />)}
					</Route>

					<Route element={ <PublicRoute /> }>
						{Pages.Public.map(p => <Route key={p.id} path={p.path} element={p.element} />)}
					</Route>
				</Routes>
			</AuthContext.Provider>
		</div>
	);
}

export default App;
