import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM;

root.render(
	<React.StrictMode>
		<HelmetProvider>
			<BrowserRouter>
				<header x-frame-options="sameorigin"/>
				<App />
			</BrowserRouter>
		</HelmetProvider>
	</React.StrictMode>
	,document.getElementById("root")
);

reportWebVitals();
