import 'bootstrap/dist/css/bootstrap.css';
import classes from "./CardBootstrap.module.scss";
import {
    Container, 
    Nav, 
    Navbar,
} from 'react-bootstrap';

import Head from './Head';

import { setCookie, getCookie, removeCookie, getAllCookie } from './cookies';

import React, { useState, useLayoutEffect, useRef, useCallback, useReducer, useEffect, useMemo } from 'react';

function Monitor() {
	let location = window.location.hostname;

	let disp_gauge;
	let disp_timegraph;

	const getDate = () => {
		const today = new Date();
		const month = (today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1);
		const year = today.getFullYear();
		const date = today.getDate();

		const hour = today.getHours() < 10 ? '0' + today.getHours() : today.getHours();
		const min = today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes();
		const sec = today.getSeconds() < 10 ? '0' + today.getSeconds() : today.getSeconds();
		const ampm = today.getHours() >= 12 ? 'PM' : 'AM';

		return `${year}/${month}/${date} ${hour}:${min}:${sec} ${ampm}`;
	}

	const [dt, setDt] = useState(getDate());

	if(typeof getCookie('disp_gauge') === 'undefined') {
		setCookie('disp_gauge', true, {
			path:'/',
		})
		setCookie('disp_timegraph', false, {
			path:'/',
		})
	}

	disp_gauge = getCookie('disp_gauge') === "false" ? false : true;
	disp_timegraph = getCookie('disp_timegraph') === "false" ? false : true;

	function displaystate(state, action){
        return action.type;
    }

	const [GaugeDisplay, toggleGaugeDisplay] = useReducer(
        displaystate,
        "block"
    );

	const [TimegraphDisplay, toggleTimegraphDisplay] = useReducer(
        displaystate,
        "block"
    );
	
	const gauge_btn = () => {
		setCookie('disp_gauge', true, {
			path:'/',
		})
		setCookie('disp_timegraph', false, {
			path:'/',
		})

		toggleGaugeDisplay({type:'block'});
		toggleTimegraphDisplay({type:'none'});
	}

	const timegraph_btn = () => {
		setCookie('disp_gauge', false, {
			path:'/',
		})
		setCookie('disp_timegraph', true, {
			path:'/',
		})

		toggleGaugeDisplay({type:'none'});
		toggleTimegraphDisplay({type:'block'});
	}

	const user_btn = () => {
		setCookie('disp_gauge', false, {
			path:'/',
		})
		setCookie('disp_timegraph', false, {
			path:'/',
		})

		toggleGaugeDisplay({type:'none'});
		toggleTimegraphDisplay({type:'none'});

		let redirect_URL = 'login';
		window.location.replace(redirect_URL);
	}

    const h_nav = useMemo( () => HeadNav());

	function HeadNav(){
		return (
			<div>
				<Navbar bg="secondary" variant="dark" collapseOnSelect expand='sm' >
                    <Container className={classes.navbarContainer} >
						<Navbar.Brand>Monitoring</Navbar.Brand>
                        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                        <Navbar.Collapse id='responsive-navbar-nav'>
                            <Nav id="headnav" className="flex-grow-1 ">
                                <Nav.Link onClick={gauge_btn}>Gauge</Nav.Link>
								<Nav.Link onClick={timegraph_btn}>TimeGraph</Nav.Link>
								<Nav.Link onClick={user_btn}>User</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
			</div>
		)
	}

	let grafana_gauge = 'https://' + location + process.env.REACT_APP_GAUGE;
	let grafana_timegraph = 'https://' + location + process.env.REACT_APP_TIMEGRAPH;
	
	let secTimer;

	useEffect(() => {
		secTimer = setInterval( () => {
			setDt(getDate())
		},1000)

		return () => clearInterval(secTimer);
	}, []);

	// xs < 576px, sm >= 576px, md >= 768px, lg >= 992px, xl >= 1200px, xxl >= 1400px
	return (
		<div>
			<Head />
			{/*  NavBar */}
			{h_nav}

			{dt}
			{/* Temperature/ Humidity Gauge */}
			<div>
				{disp_gauge && <iframe src={grafana_gauge} className={classes.iframe} title="gauge" type={{GaugeDisplay}}/>}
			</div>

			{/* Temperature/ Humidity TimeGraph */}
			<div>
				{disp_timegraph && <iframe src={grafana_timegraph} className={classes.iframe} title="timegraph" type={{TimegraphDisplay}}/>}
			</div>
		</div>
	);
}

export default Monitor;