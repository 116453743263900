'use strict';

import axios from 'axios';

export const Auth = async(id, pw) => {
    let SUB_URL = '/query/checkadmin';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                id:id,
                password: pw,
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        return await data;
    } catch (error) {
        // empty
    }
}

export const ChangePassword = async(id, pw) => {
    let SUB_URL = '/query/updateadminpw';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                id:id,
                password: pw,
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        return await data;
    } catch (error) {
        // empty
    }
}

export const ResetPassword = async() => {
    let SUB_URL = '/query/resetadminpw';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        return await data;
    } catch (error) {
        // empty
    }
}

export const GetPiInfoAll = async() => {
    let SUB_URL = '/query/getpiinfoall';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        return await data;
    } catch (error) {
        // empty
    }
}

export const GetBBInfoAll = async() => {
    let SUB_URL = '/query/getbbinfoall';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        return await data;
    } catch (error) {
        // empty
    }
}

export const GetSENInfoAll = async() => {
    let SUB_URL = '/query/getseninfoall';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        return await data;
    } catch (error) {
        // empty
    }
}

export const UpdateSenCount = async(dev_info) => {
    let SUB_URL = '/query/updatesencount';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                dev_info: dev_info,
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        return await data;
    } catch (error) {
        // empty
    }
}

export const UpdateDevInfo = async(dev_info) => {
    let SUB_URL = '/query/updatedevinfo';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                dev_info: dev_info,
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        return await data;
    } catch (error) {
        // empty
    }
}

export const GetUserInfo = async(user_info) => {
    let SUB_URL = '/query/getuserinfo';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        return await data;
    } catch (error) {
        // empty
    }
}

export const SetUserInfo = async(user_info) => {
    let SUB_URL = '/query/setuserinfo';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                user_info: user_info,
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        return await data;
    } catch (error) {
        // empty
    }
}


export const GetServerInfo = async(user_info) => {
    let SUB_URL = '/query/getserverinfo';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        return await data;
    } catch (error) {
        // empty
    }
}

export const SetServerInfo = async(user_info) => {
    let SUB_URL = '/query/setserverinfo';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                user_info: user_info,
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        return await data;
    } catch (error) {
        // empty
    }
}

export const SyncDBToServer = async(dev_info) => {
    let SUB_URL = '/query/syncdbto';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                "Content-Type": "application/json"
            },
            data: {
                dev_info:dev_info,
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        return await data;
    } catch (error) {
        // empty
    }
}

export const SyncDBFromServer = async() => {
    let SUB_URL = '/query/syncdbfrom';

    try {
        const {data} = await axios({
            method: 'POST',
            url: SUB_URL,
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true,
            credentials: "same-origin",
        })

        return await data;
    } catch (error) {
        // empty
    }
}
