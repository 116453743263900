import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import Version from './Version';

import classes from './Userstatus.module.scss';
import 'bootstrap/dist/css/bootstrap.css';

import swal from 'sweetalert2';
// import swal from 'sweetalert2/dist/sweetalert2.js';
// import 'sweetalert2/src/sweetalert2.scss'

import { AuthContext } from './Auth-context';

import Head from './Head';

import {
    GetUserInfo,
    SetUserInfo,
} from './auth-hook';

import { 
    Nav, 
    Navbar,
    Container,
    Table, 
    Card, 
    Col, 
    Row, 
    Form,
} from 'react-bootstrap';

let user_info = {
    users : [],
}

const Userstatus = () => {
    let [UserInfo, setUserInfo] = useState(null);
    const { userState } = useContext(AuthContext);

    const fetchData = useCallback(async() => {
        await get_user_info();
    }, []);

    useEffect(() => {
        fetchData().catch();
    }, [fetchData]);

    const get_user_info = async() => {
        try {
            const data = await GetUserInfo();

            user_info.users.length = 0;

            if(data.length === 0){
                user_info.users[0] = {
                    id : 1,
                    phone_number: '',
                    user_name : '',
                    remove : false,
                }
            }
            else {
                for(let i = 0; i < data.length; i++) {
                    user_info.users.push(data[i]);
                    user_info.users[i].remove = false;
                }
            }

            setUserInfo(user_info.users);
            return await data;
        } catch (error) {
            // empty
        }
    }

    const set_user_info = async() => {
        try {
            const data = await SetUserInfo(user_info.users);
        
            return await data;
        } catch (error) {
            // empty
        }
    }

    function SetRemove(props){
        let event_setremove = ((key) => {
            user_info.users.find((i) => {
                if(i.id === props.value.id){
                    i.remove = true;
                }
            });

            const newArr = [...user_info.users];
            setUserInfo(newArr);
        })

        return (
            <div>
                <button type='button' className={classes.user_configRemoveBtn} id={props.value.id + '_' + props.value.user_name} onClick={event_setremove}>Remove</button>
            </div>
        )
    }

    function SetAdd(props){
        const adduser_handleClick = () => {
            user_info.users[user_info.users.length] = {
                id : user_info.users[user_info.users.length - 1].id + 1,
                phone_number: '',
                user_name : '',
                remove : false,
            }
    
            const newArr = [...user_info.users];
            setUserInfo(newArr);
        }

        return (
            <div>
                <button className={classes.user_configCancelBtn} onClick={adduser_handleClick}>ADD</button>
            </div>
        )
    }
    
    const { register, setValue, control, formState: { errors } } = useForm({mode: "onChange"});

    const User_Info = (() => {
        const user_list_info = [];
        const user_title_info = [];
        
        const autoHyphen = (target) => {
            const number = target.value.trim().replace(/[^0-9]/g, "");
            
            if (number.length < 4){
                setValue('phone_number', number);
            }
            else if (number.length < 7) {
                setValue('phone_number', number.replace(/(\d{3})(\d{1})/, "$1-$2"));
            } 
            else if (number.length < 11) {
                setValue('phone_number', number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3"));
            }

            else if (number.length < 12) {
                setValue('phone_number', number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
            }
            else if(number.length >= 12) {
                setValue('phone_number', number.slice(0, 11).replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
            }
        }

        // data-set
        for(let idx1 = 0; idx1 < user_info.users.length; idx1++){
            if(!user_info.users[idx1].remove) {
                // new set from 'add' button
                if(user_info.users[idx1].phone_number == '') {
                    user_list_info.push(
                        <tbody key={ 'tBody_u_' + idx1 } id={ 'tBody_u_' + idx1 } >
                            <tr>
                                <td >
                                    <label htmlFor='id' className={ classes.user_text_black }>{user_info.users[idx1].id}</label>
                                </td>
                                <td>
                                    <Form.Control id={ 'phone_' +  user_info.users[idx1].id } placeholder="010-1234-5678" inputMode="numeric"
                                        {...register('phone_number', {
                                            maxLength:{
                                                value:13,
                                                message:'Invalid phone number',
                                            },
                                            onChange:(e) => {
                                                autoHyphen(e.target);
                                            },
                                        })}
                                        />
                                    {errors?.phone_number?.message}
                                </td>
                                <td>
                                    <Form.Control id={ 'user_name_' +  user_info.users[idx1].id } placeholder="홍길동" />
                                </td>
                                <td>
                                    <SetRemove id={'setremove_' + idx1} value={user_info.users[idx1]}></SetRemove>
                                </td>
                            </tr>
                        </tbody>
                    )
                }
                // set from db
                else {
                    user_list_info.push(
                        <tbody key={ 'tBody_u_' + idx1 } id={ 'tBody_u_' + idx1 } >
                            <tr>
                                <td >
                                    <label htmlFor='id' className={ classes.user_text_black }>{user_info.users[idx1].id}</label>
                                </td>
                                <td>
                                    <label className={ classes.user_text_black } id={ 'lb_phone_' +  user_info.users[idx1].id } >{user_info.users[idx1].phone_number}</label>
                                </td>
                                <td>
                                    <label className={ classes.user_text_black } id={ 'lb_user_name_' +  user_info.users[idx1].id } >{ user_info.users[idx1].user_name }</label>
                                </td>
                                <td>
                                    <SetRemove id={'setremove_' + idx1} value={user_info.users[idx1]}></SetRemove>
                                </td>
                            </tr>
                        </tbody>
                    )
                }
            }
        }

        // title
        if(user_info.users.length > 0){
            user_title_info.push(
                <Col key={'col_u_' + 0} id={'col_u_' + 0} >
                    <Card key={'card_u_' + 0} id={'card_u_' + 0} className={classes.user_info_card}>
                        <Card.Body key={'cardbody_u_' + 0} id={'cardbody_u_' + 0}>
                            <Card.Title id='user_list' >
                                <Form>
                                    <Row className={classes.user_cardTitle}>
                                        <Col>
                                            <h3 htmlFor='user_title' className={ classes.user_boldtext_black }>User List</h3>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Title>

                            <Card.Text as="div" id = "user_text" >
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <td>
                                                <label htmlFor='user_id' className={ classes.user_table_title }>ID</label>
                                            </td>
                                            <td>
                                                <label htmlFor='phone_number' className={ classes.user_table_title } >Phone number</label>
                                            </td>
                                            <td>
                                                <label htmlFor='user_name' className={ classes.user_table_title }>User Name</label>
                                            </td>
                                            <td>
                                                <SetAdd id='setadd' ></SetAdd>
                                            </td>
                                        </tr>
                                    </thead>

                                    {user_list_info}
                                </Table>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            )
        }

        return(
            <div>
                <Row  className="values" id={'Rows_pi_info'}>
                    {user_title_info}
				</Row>
            </div>
        )
    })

    const BottomButtons = (() => {
        return (
            <div id='bottom_btns_layout' className={classes.user_config_align_space_between}>
                <Col>
                    <Row>
                        <Col sm={6} xs={6}><button type='button' className={classes.user_configOKBtn} onClick={submit_handleClick}>Submit</button></Col>
                        <Col sm={6} xs={6}><button type='button' className={classes.user_configCancelBtn} onClick={cancel_handleClick}>Cancel</button></Col>
                    </Row>
                </Col>
			</div>
        )
    })

    const init_array = async() => {
        user_info = {
            users : [],
        }

        setUserInfo(null);
    }
    
    const submit_handleClick = async() => {
        for(let i = 0; i < user_info.users.length; i++){
            let phone_number_element = document.getElementById('phone_' + user_info.users[i].id);
            let user_name_element = document.getElementById('user_name_' + user_info.users[i].id);

            if(phone_number_element !== null){
                if(phone_number_element.value !== ""){
                    user_info.users[i].phone_number = phone_number_element.value;
                }
                else {
                    user_info.users[i].phone_number = phone_number_element.placeholder;
    
                    await swal.fire({
                        title: 'Update user info',
                        text: 'Can not Empty phone_number. Please check..',
                        timer: 2000,
                        toast:true,
                        icon: "error",
                        position: "center",
                        showConfirmButton: false,
                        timerProgressBar : true,
                    })
                    return false;
                }
            }
            
            if(user_name_element !== null){
                if(user_name_element.value !== ""){
                    user_info.users[i].user_name = user_name_element.value;
                }
                else {
                    user_info.users[i].user_name = user_name_element.placeholder;
    
                    await swal.fire({
                        title: 'Update user info',
                        text: 'Can not Empty user name. Please check..',
                        timer: 2000,
                        toast:true,
                        icon: "error",
                        position: "center",
                        showConfirmButton: false,
                        timerProgressBar : true,
                    })
                    return false;
                }
            }
        }

        let set_user_result = await set_user_info();
        
        if(set_user_result){
            await swal.fire({
                title: 'Update user info',
                text: 'Update success.',
                timer: 2000,
                toast:true,
                icon: "success",
                position: "center",
                showConfirmButton: false,
                timerProgressBar : true,
            })
            .then( () => {
                init_array()
                .then( () => {
                    fetchData().catch();
                })
            })
        }
        else {
            await swal.fire({
                title: 'Update user info',
                text: 'Can not complete. Try again.',
                timer: 2000,
                toast:true,
                icon: "error",
                position: "center",
                showConfirmButton: false,
                timerProgressBar : true,
            })
        }
    }

    const cancel_handleClick = () => {
        let redirect_URL = userState.user + '/status';
        // let redirect_URL = 'status';
        window.location.replace(redirect_URL);
    }

    const dev_status_btn = async () => {
        await swal.fire({
            text: 'Did you saved user info already?',
            icon: "success",
            position: "center",
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        })
        .then((result) => {
            // console.log(result)
            if(result.isConfirmed){
                swal.fire({
                    text: 'OK. Redirect Page.',
                    timer: 1000,
                    toast:true,
                    icon: "success",
                    position: "center",
                    showConfirmButton: false,
                    timerProgressBar : true,
                })
                .then(() => {
                    let redirect_URL = userState.user + '/status';
                    // let redirect_URL = 'status';
                    window.location.replace(redirect_URL);
                })
            }
            else if(result.isDenied){
                swal.fire({
                    text: 'Save user info first.',
                    timer: 1000,
                    toast:true,
                    icon: "error",
                    position: "center",
                    showConfirmButton: false,
                    timerProgressBar : true,
                })
            }
        })
    }

    const user_status_btn = async () => {
        await swal.fire({
            title: 'No response',
            text: 'This is a Users Page.',
            timer: 1500,
            toast:true,
            icon: "error",
            position: "center",
            showConfirmButton: false,
            timerProgressBar : true,
        })
    }

    function HeadNav(){
		return (
			<div>
				<Navbar bg="secondary" variant="dark" collapseOnSelect expand='sm' >
                    <Container className={classes.navbarContainer} >
						<Navbar.Brand>Admin Page</Navbar.Brand>
                        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                        <Navbar.Collapse id='responsive-navbar-nav'>
                            <Nav id="headnav" className="flex-grow-1 " >
								<Nav.Link onClick={dev_status_btn}>Devices</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
			</div>
		)
	}

    return (
        <div>
            <Head />
            <HeadNav />

            <Version />
            <User_Info />
            <BottomButtons />
        </div>
    )
}

export default Userstatus;