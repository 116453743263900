import React from 'react';
import './NotFound.module.scss';
import notfoundimg from '../assets/notfound.svg';

function NotFound() {
	return(
		<div>
            <div className="main_404"> 
                <img src={notfoundimg}  alt="face"/>
            </div>
		</div>
	)
}

export default NotFound;