import React from 'react';

const Version = () => {

    return(
        <div>
            <h4 >{process.env.REACT_APP_VERSION}</h4>
        </div>
    )
}

export default Version;