import React from 'react';

import classes from "./Login.module.scss";
import passwordIcon from "../assets/icon_password.svg";

import { useForm } from 'react-hook-form';

import { ChangePassword } from './auth-hook';

const ChangeAdminPassword = () => {
    const { register, watch, formState: { errors } } = useForm({mode: "onChange"});

    const submitHandler = async() => {
		let user_id = 'admin';
		let password = document.getElementById('confirm-password').value;

        await ChangePassword(user_id, password);

        let redirect_URL = '../login';
        window.location.replace(redirect_URL);
	};

    const cancelHandler = async() => {
        let redirect_URL = '../login';
        window.location.replace(redirect_URL);
	};

    return(
        <div>
            <div>
                <h3 className={classes.head_title}>Change Admin Password</h3>
            </div>
            <form className={classes.form} >
                <div>
                    <img
                        className={classes.login_icon}
                        src={passwordIcon}
                        alt="Password icon"
                        htmlFor="user-password"
                    ></img>
                    <input
                        className={classes.login_input}
                        type="password"
                        id="old-password"
                        name="user-password"
                        autoComplete="off"
                        placeholder="Old Password"
                    ></input>
                </div>
                
                <div>
                    <img
                        className={classes.login_icon}
                        src={passwordIcon}
                        alt="Password icon"
                        htmlFor="user-password"
                    ></img>
                    <input
                        className={classes.login_input}
                        type="password"
                        id="new-password"
                        name="user-password"
                        autoComplete="off"
                        placeholder="New Password"

                        {...register('new_password', {
                            required:'Can not be Null.',
                            minLength: {
                                value: 8,
                                message:
                                //   '비밀번호는 숫자, 영문 대문자, 소문자, 특수문자를 포함한 8글자 이상이어야 합니다.',
                                //   'Your password should be over 8 alphanumeric, include 1 number, 1 alphabet and 1 special character.',
                                    'Your password should be over 8 alphanumeric, include 1 number, 1 upper case alphabet, 1 lower case alphabet and 1 special character.',
                            },
                            pattern: {
                                value:
                                    // / ^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,                                   //최소 8자 + 최소 한개의 영문자 + 최소 한개의 숫자
                                    // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,    // 최소 8자 + 최소 한개의 영문자 + 최소 한개의 숫자 + 최소 한개의 특수 문자
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, // 최소 8자 + 최소 한개의 소문자 + 최소 한개의 대문자 + 최소 한개의 숫자 + 최소 한개의 특수 문자
                                    // / ^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$/,   // 최소 8자 + 최대 10자 + 최소 한개의 소문자 + 최소 한개의 대문자 + 최소 한개의 숫자 + 최소 한개의 특수 문자
                                message:
                                //   '비밀번호는 숫자, 영문 대문자, 소문자, 특수문자를 포함한 8글자 이상이어야 합니다.',
                                    // 'Your password should be over 8 alphanumeric, include 1 number, 1 alphabet and 1 special character.',
                                    'Your password should be over 8 alphanumeric, include 1 number, 1 upper case alphabet, 1 lower case alphabet and 1 special character.',
                            },
                        })}
                    ></input>
                    {errors?.new_password?.message}
                </div>

                <div>
                    <img
                        className={classes.login_icon}
                        src={passwordIcon}
                        alt="Password icon"
                        htmlFor="user-password"
                    ></img>
                    <input
                        className={classes.login_input}
                        type="password"
                        id="confirm-password"
                        name="user-password"
                        autoComplete="off"
                        placeholder="Confirm Password"

                        {...register('confirm_password', {
                            required:true,
                            validate: (val: string) => {
                                if(watch('new_password') != val){
                                    return "Your passwords not matched.";
                                }
                            },
                        })}
                    ></input>
                    {errors?.confirm_password?.message}
                </div>

                <div className={classes.align_space_between}>
                    <button type='button' onClick={submitHandler} className={classes.loginBtn} >Login</button>
                    <button type='button' onClick={cancelHandler} className={classes.cancelBtn} >Cancel</button>
                </div>
            </form>
        </div>
    )
}

export default ChangeAdminPassword;