import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
// import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import Version from './Version';

import classes from './Userstatus.module.scss';
import 'bootstrap/dist/css/bootstrap.css';

import swal from 'sweetalert2';
// import swal from 'sweetalert2/dist/sweetalert2.js';
// import 'sweetalert2/src/sweetalert2.scss'

import { AuthContext } from './Auth-context';

import Head from './Head';

import {
    GetServerInfo,
    SetServerInfo,
} from './auth-hook';

import { 
    Nav, 
    Navbar,
    Container,
    Table, 
    Card, 
    Col, 
    Row, 
    Form,
} from 'react-bootstrap';

let server_info = {
    servers : [],
}

const ServerInfo = () => {
    let [ServerInfo, setServerInfo] = useState(null);
    const { userState } = useContext(AuthContext);

    const fetchData = useCallback(async() => {
        await get_server_info();
    }, []);

    useEffect(() => {
        fetchData().catch();
    }, [fetchData]);

    const get_server_info = async() => {
        try {
            const data = await GetServerInfo();

            server_info.servers.length = 0;

            if(data.length === 0){
                server_info.servers[0] = {
                    id : 1,
                    addr: '',
                    remove : false,
                }
            }
            else {
                for(let i = 0; i < data.length; i++) {
                    server_info.servers.push(data[i]);
                    server_info.servers[i].remove = false;
                }
            }

            setServerInfo(server_info.servers);
            return await data;
        } catch (error) {
            // empty
        }
    }

    const set_server_info = async() => {
        try {
            const data = await SetServerInfo(server_info.servers);
        
            return await data;
        } catch (error) {
            // empty
        }
    }

    function SetRemove(props){
        let event_setremove = ((key) => {
            server_info.servers.find((i) => {
                if(i.id === props.value.id){
                    i.remove = true;
                }
            });

            const newArr = [...server_info.servers];
            setServerInfo(newArr);
        })

        return (
            <div>
                <button type='button' className={classes.user_configRemoveBtn} id={props.value.id} onClick={event_setremove}>Remove</button>
            </div>
        )
    }

    function SetAdd(props){
        const adduser_handleClick = () => {
            server_info.servers[server_info.servers.length] = {
                id : server_info.servers[server_info.servers.length - 1].id + 1,
                addr : '',
                remove : false,
            }
    
            const newArr = [...server_info.servers];
            setServerInfo(newArr);
        }

        return (
            <div>
                <button className={classes.user_configCancelBtn} onClick={adduser_handleClick}>ADD</button>
            </div>
        )
    }
    
    const { register, setValue, control, formState: { errors } } = useForm({mode: "onChange"});

    const Server_Info = (() => {
        const server_list_info = [];
        const server_title_info = [];
        

        // data-set
        for(let idx1 = 0; idx1 < server_info.servers.length; idx1++){
            if(!server_info.servers[idx1].remove) {
                // new set from 'add' button
                if(server_info.servers[idx1].addr == '') {
                    server_list_info.push(
                        <tbody key={ 'tBody_u_' + idx1 } id={ 'tBody_u_' + idx1 } >
                            <tr>
                                <td >
                                    <label htmlFor='id' className={ classes.user_text_black }>{server_info.servers[idx1].id}</label>
                                </td>
                                <td>
                                    <Form.Control id={ 'server_name_' +  server_info.servers[idx1].id } placeholder="monitor.ubatt.co.kr" />
                                </td>
                                <td>
                                    <SetRemove id={'setremove_' + idx1} value={server_info.servers[idx1]}></SetRemove>
                                </td>
                            </tr>
                        </tbody>
                    )
                }
                // set from db
                else {
                    server_list_info.push(
                        <tbody key={ 'tBody_u_' + idx1 } id={ 'tBody_u_' + idx1 } >
                            <tr>
                                <td >
                                    <label htmlFor='id' className={ classes.user_text_black }>{server_info.servers[idx1].id}</label>
                                </td>
                                <td>
                                    <label className={ classes.user_text_black } id={ 'lb_addr_' +  server_info.servers[idx1].id } >{server_info.servers[idx1].addr}</label>
                                </td>
                                <td>
                                    <SetRemove id={'setremove_' + idx1} value={server_info.servers[idx1]}></SetRemove>
                                </td>
                            </tr>
                        </tbody>
                    )
                }
            }
        }

        // title
        if(server_info.servers.length > 0){
            server_title_info.push(
                <Col key={'col_u_' + 0} id={'col_u_' + 0} >
                    <Card key={'card_u_' + 0} id={'card_u_' + 0} className={classes.user_info_card}>
                        <Card.Body key={'cardbody_u_' + 0} id={'cardbody_u_' + 0}>
                            <Card.Title id='server_list' >
                                <Form>
                                    <Row className={classes.user_cardTitle}>
                                        <Col>
                                            <h3 htmlFor='server_title' className={ classes.user_boldtext_black }>Server List</h3>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Title>

                            <Card.Text as="div" id = "server_text" >
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <td>
                                                <label htmlFor='server_id' className={ classes.user_table_title }>ID</label>
                                            </td>
                                            <td>
                                                <label htmlFor='addr' className={ classes.user_table_title } >Server Address</label>
                                            </td>
                                            <td>
                                                <SetAdd id='setadd' ></SetAdd>
                                            </td>
                                        </tr>
                                    </thead>

                                    {server_list_info}
                                </Table>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            )
        }

        return(
            <div>
                <Row  className="values" id={'Rows_pi_info'}>
                    {server_title_info}
				</Row>
            </div>
        )
    })

    const BottomButtons = (() => {
        return (
            <div id='bottom_btns_layout' className={classes.user_config_align_space_between}>
                <Col>
                    <Row>
                        <Col sm={6} xs={6}><button type='button' className={classes.user_configOKBtn} onClick={submit_handleClick}>Submit</button></Col>
                        <Col sm={6} xs={6}><button type='button' className={classes.user_configCancelBtn} onClick={cancel_handleClick}>Cancel</button></Col>
                    </Row>
                </Col>
			</div>
        )
    })

    const init_array = async() => {
        server_info = {
            servers : [],
        }

        setServerInfo(null);
    }
    
    const submit_handleClick = async() => {
        for(let i = 0; i < server_info.servers.length; i++){
            let addr_element = document.getElementById('addr_' + server_info.servers[i].id);
            
            if(addr_element !== null){
                if(addr_element.value !== ""){
                    server_info.servers[i].addr = addr_element.value;
                }
                else {
                    server_info.servers[i].addr = addr_element.placeholder;
    
                    await swal.fire({
                        title: 'Update server info',
                        text: 'Can not Empty server address. Please check..',
                        timer: 2000,
                        toast:true,
                        icon: "error",
                        position: "center",
                        showConfirmButton: false,
                        timerProgressBar : true,
                    })
                    return false;
                }
            }
        }

        let set_server_result = await set_server_info();
        
        if(set_server_result){
            await swal.fire({
                title: 'Update server info',
                text: 'Update success.',
                timer: 2000,
                toast:true,
                icon: "success",
                position: "center",
                showConfirmButton: false,
                timerProgressBar : true,
            })
            .then( () => {
                init_array()
                .then( () => {
                    fetchData().catch();
                })
            })
        }
        else {
            await swal.fire({
                title: 'Update server info',
                text: 'Can not complete. Try again.',
                timer: 2000,
                toast:true,
                icon: "error",
                position: "center",
                showConfirmButton: false,
                timerProgressBar : true,
            })
        }
    }

    const cancel_handleClick = () => {
        let redirect_URL = userState.user + '/status';
        // let redirect_URL = 'status';
        window.location.replace(redirect_URL);
    }

    const dev_status_btn = async () => {
        await swal.fire({
            text: 'Did you saved server info already?',
            icon: "success",
            position: "center",
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        })
        .then((result) => {
            if(result.isConfirmed){
                swal.fire({
                    text: 'OK. Redirect Page.',
                    timer: 1000,
                    toast:true,
                    icon: "success",
                    position: "center",
                    showConfirmButton: false,
                    timerProgressBar : true,
                })
                .then(() => {
                    let redirect_URL = userState.user + '/status';
                    // let redirect_URL = 'status';
                    window.location.replace(redirect_URL);
                })
            }
            else if(result.isDenied){
                swal.fire({
                    text: 'Save server info first.',
                    timer: 1000,
                    toast:true,
                    icon: "error",
                    position: "center",
                    showConfirmButton: false,
                    timerProgressBar : true,
                })
            }
        })
    }

    const server_status_btn = async () => {
        await swal.fire({
            title: 'No response',
            text: 'This is a Servers Page.',
            timer: 1500,
            toast:true,
            icon: "error",
            position: "center",
            showConfirmButton: false,
            timerProgressBar : true,
        })
    }

    function HeadNav(){
		return (
			<div>
				<Navbar bg="secondary" variant="dark" collapseOnSelect expand='sm' >
                    <Container className={classes.navbarContainer} >
						<Navbar.Brand>Admin Page</Navbar.Brand>
                        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                        <Navbar.Collapse id='responsive-navbar-nav'>
                            <Nav id="headnav" className="flex-grow-1 " >
								<Nav.Link onClick={dev_status_btn}>Devices</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
			</div>
		)
	}

    return (
        <div>
            <Head />
            <HeadNav />

            <Version />
            <Server_Info />
            <BottomButtons />
        </div>
    )
}

export default ServerInfo;